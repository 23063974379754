exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-graphics-js": () => import("./../../../src/pages/graphics.js" /* webpackChunkName: "component---src-pages-graphics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ux-ui-js": () => import("./../../../src/pages/ux-ui.js" /* webpackChunkName: "component---src-pages-ux-ui-js" */),
  "component---src-templates-portfolio-gallery-js": () => import("./../../../src/templates/portfolio-gallery.js" /* webpackChunkName: "component---src-templates-portfolio-gallery-js" */)
}

